'use strict';

angular.module('windmanagerApp')
  .directive('listRepair', function() {
    return {
      templateUrl: 'components/directives/repair/listRepair/listRepair.html',
      restrict: 'EA',
      scope: {
        turbine: '=',
        blade: '=',
        components: '=',
        daterange: '='
      },
      controller: 'ListRepairCtrl',
      controllerAs: 'listRepair',
      link: function(scope, element, attrs) {
      }
    };
  });
